
const languageMap = {
    "Calliope": "Calliope",
    "Calliope·Lite": "Calliope·Lite",
    "slogan": "用可视化叙事描绘未来!",
    "UploadDescription": "上传数据文件生成可视化数据洞察报表",
    "About": "关于",
    "INSIGHTFUL": "洞察",
    "EFFICIENT": "高效",
    "EXPRESSIVE": "表现",
    "About us": "关于我们",
    "Contact us": "联系我们",
    "WeiChat": "微信",
    "Tiktok": "抖音",
    "Click to upload": "点击上传",
    "Upload": "上传文件",
    "or drag a csv file here": "或者拖拽文件上传(小于5MB)",
    "logIn": "登陆",
    "logOut": "退出",
    "ToFactsheet": "生成事实表单",
    "ToDashboard": "生成可视化大屏",
    "ToDataComics": "生成数据漫画",
    "ToDataVideos": "生成数据视频",
    "ToH5": "生成H5页面",
    "Coverting": "生成中",
    "Success": "生成成功",
    "Howtoconvert": "如何将数据文件转换为其他可视化图表内容:",
    "step1": "1.将您的CSV格式下的数据文件上传至 Calliope·Lite。",
    "step2": "2.系统将自动为您抽取数据内涵并生成相应的可视化内容呈现。",
    "step3": "3.将生成结果下载到您的设备或在网络中与他人共享。",
    "1": "便捷化的使用",
    "11": "将您的CSV格式下的数据文件拖放至上边的文件接收框内，系统将自动为您生成所选的可视化图表内容。",
    "2": "不用担心安全问题",
    "21": "确保文档安全是我们的首要任务。我们的所有文件转换器均使用高级SSL加密技术。此外，我们也会自动从服务器销毁所有文件。",
    "3": "适用所有设备",
    "31": "无需注册或安装软件。在线PDF转换器可完美适用于所有设备以及Edge、Firefox、Chrome 和 Safari 等现代浏览器。",
    "4": "高质量",
    "41": "亲自体验一下吧！系统已采用先进的人工智能技术以确保可视化内容的生成质量。",
    "5": "自动生成",
    "51": "通过 Calliope Lite 您可以将您的数据文件自动转换成各种不同的可视化数据洞察展现形式包括：事实表单、可视化大屏、数据漫画，以及数据视频等。",
    "6": "随时随地使用",
    "61": "只要有网络连接，您就可以在任何地方使用免费的数据可视化内容生成工具。Calliope Lite完全在云端运作。",
    "Coming soon": "功能正在开发中...",
    "convertedTxt": "基于您的数据文件，已经生成了一个可视化的事实表单!",
    "convertedTxtH5": "基于您的数据文件，已经生成了一个可视化的H5页面!",
    "Visit":"使用",
    "VisitCalliope":"编辑你的数据事实报表",
    "Download": "下载",
    "Regnerate": "重新生成",
    "Open H5": "打开",
    "nameEditor": "请输入故事名称",
    "Datafacts": "数据事实",
    "Edit": "编辑",
    "Rate": "评分",
    "RateError": "您已经打过分数了！",
    "Uploading file": "文件上传中",
    "upload failed": "文件上传失败",
    "larger than 5MB": "用于功能展示的 Calliope Lite 系统仅用于处理小于5MB的CSV文件。",
    "downloading": "正在下载",
    "adding": "正在添加新数据事实",
    "Morefacts":"更多事实",
    "ChartEditor": "图表编辑器",
    "Save": "保存",
    "Cancel": "取消",
    "type": "类型",
    "measure": "测度",
    "subspace": "子空间",
    "groupby": "分组",
    "group by": "分组",
    "focus": "焦点",
    "value": "数值",
    "trend": "趋势",
    "categorization": "分类",
    "difference": "差异",
    "proportion": "占比",
    "distribution": "分布",
    "rank": "排名",
    "association": "相关",
    "extreme": "极值",
    "outlier": "异常",
    "visualization": "可视化",
    "field": "字段",
    "breakdown": "分组",
    "loadfile":"文件格式不支持，请选择上传 CSV 格式下的数据文件",
    "Loading in":"加载中",
    "Load failed":"加载失败",
    "Copy the link":"复制链接",
    "confirm":"确认",
    "cancel":"取消",
    "The story of ":"的故事",
    "succ":"复制成功"
}
  module.exports = {
   languageMap:languageMap
  }