import axios from 'axios';

export async function fetchStory(id) {
    return axios({
        method: "get",
        "url": `https://service.datacalliope.com:8002/data/share/${id}.json`,
        config: {
            "headers": {
                'Content-Type': 'application/json; charset=utf-8'
            },
        }
    })
}