import { facts2charts } from '../tool/fact2vis';
import ActionType from '../action/type';
// import { genTitle } from "../sentencer/index";
import _ from 'lodash';


const initialState = {
    fileName: '.csv',
    title: '',
    data: [],
    schema: [],
    method: 'sig',
    facts: [],
    unusedFacts:[],
    relations: [],
    selectedFactIndex: 0,
    maxStoryLength: 6,
    timeLimit: 2000,// for each iteration
    information: 40, // default 50 bits for 6 facts
    resultCoverage: 1,
    chartDiversity: 0,
    aggregationLevel: 0,
    rewardWeight: {
        logicality: 0.33,
        diversity: 0.33,
        integrity: 0.33,
    },
    generateProgress: 0,
};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    let newFacts;
    switch (action.type) {
        case ActionType.INIT_RELATION:
            newState.relations = action.storyRelations;
            newState.resultCoverage = 0;
            return newState;
        case ActionType.INIT_FACT:
            newState.facts = action.storyFacts;
            newState.resultCoverage = 0;
            return newState;
        case ActionType.SELECT_FACT:
            newState.selectedFactIndex = action.index;
            return newState;
        case ActionType.ADD_FACT:
            newFacts = newState.unusedFacts.slice();
            newState.unusedFacts = newFacts.concat(action.facts);
            return newState;
        case ActionType.UPDATE_FACT:
            newState.selectedFactIndex = action.index;
            let fact = _.cloneDeep(action.fact);
            // fact.chart = getFactChartType(fact, state.data);
            newFacts = newState.facts.slice();
            newFacts[action.index] = fact;
            newState.facts = newFacts;
            newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            return newState;
        case ActionType.INSERT_FACT:
            newFacts = newState.facts.slice();
            let newUnusedFacts = newState.unusedFacts.slice();
            let newFact = newUnusedFacts.splice(action.index - newState.facts.length, 1)[0];
            newFacts.splice(action.insert, 0, newFact);
            newState.facts = newFacts;
            newState.unusedFacts = newUnusedFacts;
            newState.relations = Array(newFacts.length).fill('none'); // reset relations
            return newState;
        case ActionType.DELETE_FACT:
            if(action.index < newState.facts.length){
                newFacts = newState.facts.slice();
                newFacts.splice(action.index, 1);
                if (newFacts.length === 0) {
                    // add default fact
                    // let newFact = new Fact(FactType.VALUE);
                    // newFacts.push(newFact);
                }
                // newState.selectedFactIndex = action.index > 0 ? action.index - 1 : 0;
                newState.facts = newFacts;
                newState.selectedFactIndex = newFacts.length - 1;
                newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            }else{
                newFacts = newState.unusedFacts.slice();
                newFacts.splice(action.index - newState.facts.length, 1);
                newState.unusedFacts = newFacts;
            }
            return newState;

        case ActionType.ORDER_FACTS:
            newFacts = newState.facts.slice();
            const [moved] = newFacts.splice(action.sourceIndex, 1);
            newFacts.splice(action.destinationIndex, 0, moved);
            newState.facts = newFacts;
            newState.selectedFactIndex = action.destinationIndex;
            newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            return newState;

        case ActionType.CHANGE_METHOD:
            newState.method = action.method;
            return newState;

        case ActionType.SET_STORY_PARAMETER:
            newState.maxStoryLength = action.maxStoryLength;
            newState.information = action.information;
            newState.chartDiversity = action.chartDiversity;
            if (action.chartDiversity !== state.chartDiversity) {
                newFacts = newState.facts.slice();
                //newFacts = facts2charts(newFacts, state.data, action.chartDiversity);
                newFacts = facts2charts(newFacts, state.schema, action.chartDiversity);
                newState.facts = newFacts;
            }
            newState.timeLimit = action.timeLimit;
            return newState;

        case ActionType.SET_AGGREGATION_LEVEL:
            newState.aggregationLevel = action.level;
            return newState;

        case ActionType.SET_REWARD_WEIGHT:
            let newWeight = Object.assign({}, newState.rewardWeight);
            newWeight.logicality = action.logicality;
            newWeight.diversity = action.diversity;
            newWeight.integrity = action.integrity;
            newState.rewardWeight = newWeight;
            return newState;

        case ActionType.GENERATE_STORY:
            newState.facts = action.facts;
            newState.relations = action.relations;
            newState.resultCoverage = action.coverage;
            newState.selectedFactIndex = action.facts.length - 1
            return newState;



        case ActionType.CHANGE_TITLE:
            newState.title = action.title;
            return newState;



            case ActionType.CHANGE_Mobile_TITLE:
                newState.title = action.title;
                return newState;
        default:
            break;
    }
    return newState;
}