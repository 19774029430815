import React, { Component } from 'react';
import { fact2chart } from '../../../tool/fact2vis';
import { isValid } from '../../../../src/view/FactView/helper'
import { hightlight } from './helper'

export default class EventView extends Component {
    constructor(props) {
        super(props);
        this.scriptRef = React.createRef();
    }

    clickFact = () => {
        const { selectFact, index } = this.props;
        selectFact(index);
    }

    endEditing = () => {
        let script = this.scriptRef.current.innerText;
        let { fact, index } = this.props;
        fact.generatedScript = script;
        this.props.updateFact(index, fact);
    }

    shouldComponentUpdate = () =>{
        return false;
    }

    // shouldComponentUpdate(nextProps) {
    //     // console.log(this.props)
    //     if(this.props.width === nextProps.width) return false
    //     else return true
    // }


    render() {
        let { uuid, fact, data,
            //width, 
            forPublish, schema } = this.props;
        let specData = {
            "schema": schema,
        };
        let chartSize = 'middle';
        let vis;
        if (fact.aggregated && fact.aggregatedFact) {
            let aggregatedFact = fact.aggregatedFact;
            let compoundScript = fact.generatedScript + " " + aggregatedFact.generatedScript;
            vis = fact2chart(specData, uuid, fact, data, chartSize, true);
            return (
                <div>
                <div className="mobile-graphic">
                    {vis}
                    {/* <EventView key={events[i].id} index={events[i].index} uuid={events[i].id} fact={events[i].fact} data={data} width={mobileClientWidth} /> */}
                </div>
                <div className="mobile-script">
                    <div className="script-content">
                        <div>
                            {compoundScript}
                        </div>
                    </div>
                </div>
            </div>
            )
        } else {
            if (isValid(this.props.fact)) {
                vis = fact2chart(specData, uuid, fact, data, chartSize);
            }
            // console.log(isValid(this.props.fact), width)
            return (
                <div>
                    <div className="mobile-graphic">
                        {vis}
                        {/* <EventView key={events[i].id} index={events[i].index} uuid={events[i].id} fact={events[i].fact} data={data} width={mobileClientWidth} /> */}
                    </div>
                    <div className="mobile-script">
                        <div className="script-content">
                            <div ref={this.scriptRef} spellCheck="false" suppressContentEditableWarning="true" contentEditable={forPublish ? "false" : "true"} onBlur={this.endEditing} dangerouslySetInnerHTML={{ __html: hightlight(fact) }} >
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
