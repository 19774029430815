
const languageMap = {
    "Calliope": "Calliope",
    "Calliope·Lite": "Calliope·Lite",
    "slogan": "Powering the future with data narratives!",
    "UploadDescription": "Upload your data for visual narrative generation",
    "About": "About",
    "INSIGHTFUL": "INSIGHTFUL",
    "EFFICIENT": "EFFICIENT",
    "EXPRESSIVE": "EXPRESSIVE",
    "About us": "About us",
    "Contact us": "Contact us",
    "WeiChat": "WeiChat",
    "Tiktok": "Tiktok",
    "Click to upload": "Click to upload",
    "Upload": "Upload",
    "or drag a csv file here": "or drag a csv file here (no more than 5MB) ",
    "logIn": "log in",
    "logOut": "log out",
    "ToFactsheet": "to factsheet",
    "ToDashboard": "to dashboard",
    "ToDataComics": "to comics",
    "ToDataVideos": "to video",
    "ToH5": "to H5",
    "Coverting": "Generating",
    "Success": "Success",
    "Howtoconvert": "How to create visual narratives:",
    "step1": "1. Upload your data file to Calliope·Lite. ",
    "step2": "2. Calliope·Lite will instantly analyze your data and show the results in a chosen visual narrative form.",
    "step3": "3. Download or share the generation results as a PDF file or H5 page.",
    "1": "Easy to use",
    "11": "Drag and drop your data file in the CSV format to the toolbox above to begin. Data files will be analyzed and shown in the visualization type you choose.",
    "2": "Privacy security",
    "21": "Your security is our priority. All our file transfers are secured with an advanced level of SSL encryption. Additionally, we destroy all files automatically from our servers.",
    "3": "Perform on all devices",
    "31": "You do not need to register or install a software. Calliope·Lite works perfectly on all devices and popular browsers: Chrome, Safari, Edge and Firefox.",
    "4": "Great quality",
    "41": "The system employs the most advanced AI technology to ensure the best quality of automated visual narrative generation.",
    "5": "Automated generation",
    "51": "You can convert your CSV data file directly into various forms of visual narratives including: Factsheet, Dashboard, Data Comics & Data Videos.",
    "6": "Access from anywhere",
    "61": "You can access the free Calliope·Lite anywhere, with an internet connection. Calliope·Lite operates fully in the cloud.",
    "Coming soon": "Coming soon...",
    "convertedTxt": "Based on your data file a visual factsheet has been generated !",
    "convertedTxtH5": "Based on your data file a visual H5 page has been generated !",
    "Visit":"Visit ",
    "VisitCalliope":"to edit your factsheet",
    "Download": "Download",
    "Open H5": "Open",
    "Regnerate": "Regnerate",
    "nameEditor": "please input name",
    "Datafacts": "Data facts",
    "Edit": "Edit",
    "Rate": "Rate",
    "RateError": "you have rated before!",
    "Uploading file": "Uploading file",
    "upload failed": "Please check your csv file",
    "larger than 5MB": "Calliope·Lite system will only process CSV files less than 5MB.",
    "downloading": "Downloading",
    "adding": "Adding new fact",
    "Morefacts":"More facts",
    "ChartEditor": "Chart Editor",
    "Save": "Save",
    "Cancel": "Cancel",
    "type": "type",
    "measure": "measure",
    "subspace": "subspace",
    "groupby": "groupby",
    "group by": "group by",
    "focus": "focus",
    "value": "value",
    "trend": "trend",
    "categorization": "categorization",
    "difference": "difference",
    "proportion": "proportion",
    "distribution": "distribution",
    "rank": "rank",
    "association": "association",
    "extreme": "extreme",
    "outlier": "outlier",
    "visualization": "visualization",
    "field": "field",
    "breakdown": "breakdown",
    "loadfile":"The file format is not supported. Please choose to upload the data file in CSV format",
    "Loading in":"Loading in",
    "Load failed":"Load failed",
    "Copy the link":"Copy the link",
    "confirm":"confirm",
    "cancel":"cancel",
    "The story of ":"The story of ",
    "succ":"copy success"
}
  module.exports = {
   languageMap:languageMap
  }  