import React, { Component } from 'react'
import 'swiper/css/swiper.css';
import uuidv4 from 'uuid/v4';
import Slider from "./slider";
import './index.css'


export default class MobileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facts: this.props.facts,
            swiper: null
        }
    }
    render() {

        const { facts,  schema } = this.props;
        if (!facts) return null;
        let events
        if (facts.length) {
            events = facts.map(function (x, i) {
                return {
                    id: uuidv4(),
                    index: i,
                    fact: x,
                }
            });
        }
        console.log(333,this.props);
        console.log("ssss",document.body.clientHeight );
        return (
            <div id='mobile-swiper' style={{ overflowY: 'hidden', height:  document.body.clientHeight }}>
                <Slider events={events} schema={schema} {...this.props} />
            </div>
        )
    }
}
