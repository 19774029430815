import React, { Component } from 'react'
import MobileView from "./view/StoryView/MobileView/index"
import { fetchStory } from './axios/api'
import * as d3 from 'd3';
import copy from 'copy-to-clipboard';
console.log(
    window.wx
);
export default class APP extends Component {
    state = {
        isShowFilter: 2,
        generateUrl: "",
        translate: require('./i18n/_ying.js').languageMap,
        storyProps: {},
        name: '',
        lanh: '',
        title: '',
        id: '',
        forPublish: false,
        platform: ''
    }
    componentWillMount = () => {
        let Url = window.location.href
        let arrUrl = Url.split("#/H5/")
        let url = arrUrl[1]
        this.setState({
            generateUrl: Url,
            id: url
        })
        fetchStory(url).then(
            (response) => {
                console.log(
                    response.data.title
                )
                this.setState({
                    name: response.data.title,
                    lanh: response.data.lanh,
                })
                console.log(response.data);
                if (response.data.lanh === "EN") {
                    this.setState({
                        translate: require("./i18n/_ying.js").languageMap
                    })
                    document.title = this.state.translate["The story of "] + this.state.name
                } else {
                    this.setState({
                        translate: require('./i18n/_zhong.js').languageMap
                    })
                    document.title = this.state.name + this.state.translate["The story of "]
                }
                this.setState({
                    title: document.title
                })
                let fileURL = response.data.generateUrl
                let schema = response.data.schema
                let numericalFields = []
                let numerical = schema.filter(d => d.type === "numerical")
                numericalFields = numerical.map(d => d.field)
                let that = this
                d3.csv(fileURL)
                    .then(function (data) {
                        data.forEach((d, i) => {
                            for (let key in d) {
                                if (numericalFields.indexOf(key) !== -1) {
                                    d[key] = parseFloat(d[key])
                                }
                            }
                        })
                        that.setState({
                            storyProps: {
                                ...response.data,
                                data: data
                            }
                        })
                    }).catch(function (error) {
                        console.log(error)
                    })
            }
        );
    }
    forPublishClick() {
        this.setState({
            forPublish: true
        })
    }
    showClick() {

        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器 
        if (isAndroid && window.__wxjs_environment === "miniprogram") {
            window.wx.miniProgram.navigateTo({ url: `/pages/Return/Return?id=${this.state.id}&&title=${this.state.title}` })
        }else {
            this.setState({
                isShowFilter: 1
            })
        }
        // if (window.__wxjs_environment === "miniprogram") {
        //     window.wx.miniProgram.navigateTo({ url: `/pages/Return/Return?id=${this.state.id}&&title=${this.state.title}` })
        // } else {
        //     this.setState({
        //         isShowFilter: 1
        //     })
        // }
    }
    CoppyUrl = (url) => {
        let timer
        copy(url);
        this.setState({
            generateUrl: this.state.translate["succ"]
        })
        timer = setInterval(() => {
            if (this.state.generateUrl === url) {
            } else {
                this.setState({
                    generateUrl: url,
                    isShowFilter: 0,
                })
                clearInterval(timer);
            }

        }, 1000)
    };
    showRe() {
        let generate_url = window.location.href
        this.setState({
            isShowFilter: 0,
            generateUrl: generate_url
        })
    }

    render() {
        const { isShowFilter, generateUrl, translate, forPublish} = this.state
        return (
            <>
                {JSON.stringify(this.state.storyProps) === "{}" ? null : <div>
                    <MobileView {...this.state.storyProps} forPublish={forPublish} />
                    {
                        forPublish === true ? <div className="share" onClick={() => this.showClick()}>
                            <div className='image'></div>
                        </div> : <div className="share" onClick={() => this.forPublishClick()}>
                                <div className="baocun">保存</div>
                            </div>
                    }
                    {isShowFilter === 1 ? <div className="black">
                        <div className="white">
                            <div className="hhh">
                                <div className="span_1"><span>{translate["Copy the link"]}</span></div>
                                <div className="span_2"><span>{generateUrl}</span></div>
                                <div className="span_3">
                                    <div className="left" onClick={() => this.showRe()}><span>{translate["cancel"]}</span></div>
                                    <div className="right" onClick={() => this.CoppyUrl(generateUrl)}><span>{translate["confirm"]}</span></div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                </div>
                }
            </>



        )
    }
}
