export default {
    GREEN: 'rgb(51, 111, 185)',
    YELLOW: 'yellow',
    BLUE: '#336fb9',
    GRAY: 'lightgray',
    BAR_BACK: '#222f3d',
    BAR: '#6CD4FF',
    BAR_HIGHTLIGHT: '#F6C02A',
    AREA: '#ffce54',
    LINE: '#ffce54',
    BG_ABOVE: '#a1d469',
    BG_BELOW: '#ed5463',
    CONVERT: ["#064969", "#E19788", "#D33346", "#96A7CE", "#B1CC70", "#4ECBC4"]
}