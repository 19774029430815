export default {
    // Data
    UPLOAD_DATA: 'UPLOAD_DATA',
    CONNECT_DATA: 'CONNECT_DATA',
    CHANGE_DATA: 'CHANGE_DATA',
    VISUALIZE_DATA: 'VISUALIZE_DATA',
    // Fact
    ADD_FACT: 'ADD_FACT',
    INSERT_FACT: 'INSERT_FACT',
    UPDATE_FACT: 'UPDATE_FACT',
    DELETE_FACT: 'DELETE_FACT',
    ORDER_FACTS: 'ORDER_FACTS',
    // Story
    SELECT_FACT: 'SELECT_FACT',
    CHANGE_METHOD: 'CHANGE_METHOD',
    SET_STORY_PARAMETER: 'SET_STORY_PARAMETER',
    SET_AGGREGATION_LEVEL: 'SET_AGGREGATION_LEVEL',
    SET_REWARD_WEIGHT: 'SET_REWARD_WEIGHT',
    GENERATE_STORY: 'GENERATE_STORY',
    CHANGE_TITLE: 'CHANGE_TITLE',
    CHANGE_Mobile_TITLE:'CHANGE_Mobile_TITLE',
    UPDATE_STORY_CARD_NUMBER: 'UPDATE_STORY_CARD_NUMBER',
    UPDATE_COMMENT_STORY_INFO: 'UPDATE_COMMENT_STORY_INFO',
    UPDATE_UUID: 'UPDATE_UUID',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',//生成进度
}